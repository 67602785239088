<template>
  <div
    id="landingpage"
    class="mt-0"
  >
    <v-app-bar
      app
    >
      <router-link
        to="/"
      >
        <v-img
          class="mr-2"
          height="50px"
          width="50px"
          alt="FantasyKombat"
          src="/logo_small.png"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div class="w-full mt-0">
        <div class="d-flex align-center mx-0 mt-0 ">
          <router-link
            to="/fight-tournaments"
            class="mr-3 hidden-sm-and-down"
          >
            <v-btn>
              Upcoming Tournaments
            </v-btn>
          </router-link>
          <v-btn
            class="mr-3 hidden-sm-and-down"
            href="https://support.fantasykombat.com/en/article/how-to-play-fantasykombat-1jf829i/"
            target="_blank"
          >
            How to play
          </v-btn>
          <router-link
            to="/contact-us"
            class="mr-3 hidden-sm-and-down"
          >
            <v-btn>
              Contact Us
            </v-btn>
          </router-link>
          <!-- Left Content -->
          <v-spacer></v-spacer>
          <div v-if="isloggedin">
            <v-btn
              color="success"
              class="mx-2"
              outlined
              small
              to="/dashboard"
            >
              Dashboard
            </v-btn>
            <v-btn
              to="/logout"
              outlined
              small
            >
              Logout
            </v-btn>
          </div>
          <GuestMenu v-else></GuestMenu>
        </div>
      </div>
    </v-app-bar>
    <div class="boxed-container w-full mt-0 padding pa-5">
      <v-row>
        <v-col
          cols="12"
          class="col-md-12"
        >
          <div class="text-center">
            <v-img
              class="mb-9 mt-12 text-center mx-auto"
              height="100%"
              width="400px"
              alt="FantasyKombat: UFC fantasy sports game"
              src="~@/assets/images/logos/nav_logo.png"
            ></v-img>
            <h1
              class="white--text mb-2 text-center"
              style="font-weight: 900; text-shadow: 3px 2px #000000"
            >
              A new Play-and-Earn Fantasy Sports game for MMA fans!<br>
            </h1>
            <p class="subheading text-center">
              <v-chip
                outlined
                class="mt-3"
              >
                More than just a pick'em, a fully fledged fantasy sports game for
                MMA fans
              </v-chip>
            </p>
            <v-btn
              class="mt-5 join"
              color="success"
              large
              elevation="6"
              to="/join"
            >
              Get Started
              <v-badge
                color="warning"
                class="mt-10 mr-0"
                overlap
                content="Beta"
              ></v-badge>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="text-center">
            <h1
              class="white--text mb-2 mt-8 pt-8 text-center"
            >
              Recruit, Train, Fight
            </h1>

            <h2>1.Recruit fighters to join your team</h2>
            <br>
            <br>
            <v-row>
              <v-col
                cols="12"
                class="col-md-4 offset-md-4"
              >
                <v-img
                  alt="MMA Fantasy Sports Game"
                  class="mt-6 screenshot mx-auto"
                  width="100%"
                  src="~@/assets/images/landingpage/market-place.png"
                ></v-img>
              </v-col>
            </v-row>
            <br><br>
            <h2 class="mt-4 pt-4">
              2. Hire an Advisor (Hall-of-famer or retired legend)<br>& set your training
              strategy to increase PowerUps
            </h2>
            <v-chip
              outlined
              class="mt-3"
            >
              PowerUps increase fighter attributes
            </v-chip>
            <br>
            <br>
            <v-row>
              <v-col
                cols="12"
                class="col-md-4 offset-md-4"
              >
                <v-img
                  alt="UFC Fantasy Sports play-to-earn Game training"
                  class="mt-6 screenshot mx-auto"
                  width="100%"
                  src="~@/assets/images/landingpage/training.png"
                ></v-img>
              </v-col>
            </v-row>
            <br><br>
            <h2 class="mt-4 pt-4">
              3. Compete in Fantasy Tournaments to receive rewards <br> based on in-game &
              real world fight
              outcomes
            </h2>
            <v-chip
              outlined
              class="mt-3"
            >
              Bonuses for picking "Fight of the night" & "Performance of the night" winners
            </v-chip>
            <br><br>

            <v-row>
              <v-col
                cols="12"
                class="col-md-4 offset-md-4"
              >
                <v-img
                  alt="MMA and UFC fight picks"
                  class="mt-6 screenshot mx-auto"
                  width="100%"
                  src="~@/assets/images/landingpage/picker.png"
                ></v-img>
              </v-col>
            </v-row>
            <br>
            <br>
            <h2 class="mt-4 pt-4">
              4. Trade fighters
            </h2>
            <v-chip
              outlined
              class="mt-3"
            >
              Fighter's value can appreciate or depreciate based on in-game and real world fight
              outcomes
            </v-chip>
            <br><br>

            <v-row>
              <v-col
                cols="12"
                class="col-md-8 offset-md-2"
              >
                <v-img
                  alt="UFC NFT marketplace"
                  class="mt-6 screenshot mx-auto"
                  width="80%"
                  src="~@/assets/images/landingpage/mini-market.jpeg"
                ></v-img>

                <v-btn
                  class="mt-12 mb-6 join"
                  color="success"
                  large
                  elevation="6"
                  to="/join"
                >
                  Get Started
                  <v-badge
                    color="warning"
                    class="mt-10 mr-0"
                    overlap
                    content="Beta"
                  ></v-badge>
                </v-btn>
                <br>
                <br>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GuestMenu from '@/layouts/components/GuestMenu.vue'

export default {
  components: {
    GuestMenu,
  },
  data() {
    return {
      user: this.$store.state.user,
      isloggedin: false,
    }
  },
  mounted() {
    if (this.user) {
      this.isloggedin = true
    }
  },
}
</script>

<style scoped>

.v-btn.join, .v-btn.signup {
  background-color: #312d4b;
}

h1, h2, h3, h4 {
  font-weight: 900;
  text-shadow: 3px 2px #000000;
}

.screenshot {
  box-shadow: 2px 2px 20px #666 !important;
  border-radius: 10px;
  border: 2px solid #fff;
  opacity: 1;
}

#landingpage .v-chip--outlined {
  background: rgba(49, 45, 75, 0.7) !important;
}

#landingpage .v-chip {
  padding: 12px;
  height: auto;
  white-space: normal;
}

#landingpage .v-chip__content {
  line-height: 20px !important;
}

.team-power-ups.v-sheet.theme--dark {
  background: transparent;
}
</style>
